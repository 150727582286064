<template>
  <h1>404</h1>
</template>

<script>
export default {
  name: '404',
  components: {

  },
  data() {
    return {

    }
  }
}
</script>
